/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";

// reactstrap components
import { Row, Col, UncontrolledTooltip } from "reactstrap";
import LogoSilo from "../../assets/img/icons/proj/SiloVirtual.png";
import LogoBzero from "../../assets/img/icons/proj/Bzerro.png";

class Download extends React.Component {
  render() {
    return (
      <>
        
            <Row className="row-grid justify-content-center" style={{ marginTop: 50 }}>
              <Col className="text-center" lg="8">
                <h2 className="display-3">
                  Veja também{" "}
                </h2>
                <div className="text-center">
                  <Row className="justify-content-center">
                    <Col lg="2" xs="4">
                      <a
                        href="https://silovirtual.com.br"
                        id="tooltip255035741"
                        target="_blank"
                      >
                        <img
                          alt="..."
                          className="img-fluid"
                          src={LogoSilo}
                        />
                      </a>
                      <UncontrolledTooltip delay={0} target="tooltip255035741">
                        Aplicativo para acompanhamento dos seus grãos nos armazéns
                      </UncontrolledTooltip>
                    </Col>
                    <Col lg="2" xs="4">
                      <a
                        href="http://bzerro.com.br"
                        id="tooltip265846671"
                        target="_blank"
                      >
                        <img
                          alt="..."
                          className="img-fluid"
                          src={LogoBzero}
                        />
                      </a>
                      <UncontrolledTooltip delay={0} target="tooltip265846671">
                        Controle seu rebanho, acompanhe suas receitas e despesas, controle seu financeiro e muito mais!
                      </UncontrolledTooltip>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          
      </>
    );
  }
}

export default Download;
