/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext, useEffect } from "react";
// reactstrap components
import {
  Button,
  Row,
  Col,
  Modal, ModalBody, ModalFooter,
  Card, CardTitle, CardText
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faMoneyCheckAlt, faMoneyBillWave, faMoneyBillAlt } from '@fortawesome/free-solid-svg-icons';

//DEV - BRST
import ModalContext from 'components/Context/ModalContext';

//class Landing extends React.Component {
export default function MConheca( props ) { 
    const [VMais, setVMais] = useState(false);
    const { CloseVejaMais } = useContext(ModalContext);

    function Close(){
      setVMais(false);
        CloseVejaMais()
    }

    function SejaCliente(){
        Close();
    }

    useEffect(() => {
        if ( props.abre === 2 ) {
            setVMais(true);
        }
    }, [props.abre]);

  return (
    <>
        <Modal onClose={Close} size={"lg"} isOpen={VMais}>
          <ModalBody>
            <Row>
              <Col className="mb-lg-auto" lg="6" md="6" sm="12" style={{ textAlign: "left" }}>
                <Card body inverse color="success">
                  <CardTitle tag="h5" cssModule={{'card-title': 'text-white'}}>
                    <FontAwesomeIcon style={{color: "white" }} icon={faMoneyCheckAlt} />  { " " } <b>Plano FREE</b>
                  </CardTitle>
                  <CardText>
                    <p><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } Apenas 1 emissor.</p>
                    <p><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } Emita até X notas mensais.</p>
                    <p><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } Faça X MDFes.</p>
                  </CardText>
                </Card>
              </Col>

              <Col className="mb-lg-auto" lg="6" md="6" sm="12" style={{ textAlign: "left" }}>
                <Card body inverse color="success">
                  <CardTitle tag="h5" cssModule={{'card-title': 'text-white'}}>
                    <FontAwesomeIcon style={{color: "white" }} icon={faMoneyBillWave} />  { " " } <b>Plano Mugiwara</b>
                  </CardTitle>
                  <CardText>
                    <p><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } Tenha até 2 emissores.</p>
                    <p><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } Tenha até 3 IE's emissoras.</p>
                    <p><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } Emita até 2X notas e 2X MDFes mensais</p>
                  </CardText>
                </Card>
              </Col>
            </Row>
            <Row style={{ display: "flex", justifyContent: "center", marginTop: 10 }}>
              <Col className="mb-lg-auto" lg="6" md="6" sm="12" style={{ textAlign: "left" }}>
                <Card body inverse color="success">
                  <CardTitle tag="h5" cssModule={{'card-title': 'text-white'}}>
                    <FontAwesomeIcon style={{color: "white" }} icon={faMoneyBillAlt} />  { " " } <b>Plano Yonkou</b>
                  </CardTitle>
                  <CardText>
                    <p><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } Tenha emissores ilimitados.</p>
                    <p><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } Tenha quantas IE's emissoras precisar.</p>
                    <p><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } NFes e MDFes ilimitados.</p>
                  </CardText>
                </Card>
              </Col>

              <Col className="mb-lg-auto" lg="6" md="6" sm="12" style={{ textAlign: "left" }}>
                <Card body inverse color="primary">
                  <CardText>
                    <p style={{ textAlign:"center", fontSize: 16 }}><b>E em todos os planos:</b></p>
                    <p style={{ fontSize: 14 }}><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } Controle de usuários temporários.</p>
                    <p style={{ fontSize: 14 }}><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } Parte relatorial completa.</p>
                    <p style={{ fontSize: 14 }}><FontAwesomeIcon style={{color: "green" }} icon={faCheck} />  { " " } Tenha controle de multiplos emissores, emissão por lote.</p>
                  </CardText>
                </Card>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => SejaCliente()}>
              Seja nosso cliente
            </Button>
            {' '}
            <Button onClick={() => Close()}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
    </>
  );
}
