/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardBody,
  FormGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
  Alert, Spinner
} from "reactstrap";

// core components
import DemoNavbar from "components/Navbars/DemoNavbar.js";
import CardsFooter from "components/Footers/CardsFooter.js";

// index page sections
import Download from "../IndexSections/Download.js";

//DEV - BRST
import ModalContext from 'components/Context/ModalContext';
import MNFE from 'components/Modais/mNFE';
import MMDFE from 'components/Modais/mMDFE';
import MConheca from 'components/Modais/mConheca';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons'; 
import axios from "axios";

import '../../assets/css/buttonZap.css';

//class Landing extends React.Component {
export default function Landing() {
  const [VMais, setVMais] = useState("");
  const [nameFocused, setnameFocused] = useState(false);   
  const [emailFocused, setemailFocused] = useState(false);   
  
  const [cNome, setcNome] = useState("");
  const [aNome, setaNome] = useState(false);
  const [cMail, setcMail] = useState("");
  const [aMail, setaMail] = useState(false);
  const [cMSG, setcMSG] = useState("");
  const [aMSG, setaMSG] = useState(false);

  const [Sev, setSev] = useState("Success");
  const [Retorno, setRetorno] = useState("");
  const [Loading, setLoading] = useState(false);

  function VejaMais(pos){
    setVMais(pos);
  }

  function CloseVejaMais(){
    setVMais(false);
  }

  async function EnviaContato() {
    let c = 0;
    setRetorno("");
    setLoading( true);
    if(cNome === "") {
      c = c + 1;
      setaNome(true);
    }else{
      setaNome(false);
    }

    if(cMail === "") {
      c = c + 1;
      setaMail(true);
    }else{
      setaMail(false);
    }

    if(cMSG === "") {
      c = c + 1;
      setaMSG(true);
    }else{
      setaMSG(false);
    }


    if( c === 0 ){
      let bd = "https://brst.com.br/app.php?DFE&cNome=" + cNome + "&cMail=" + cMail + "&cMSG=" + cMSG;
      await axios
      .get(bd)
      .then((response) => {
        //console.log(response)
        setSev("success");
        setRetorno(response.data.replace('"', ''));
        setLoading(false);

        setcNome("");
        setcMail("");
        setcMSG("");

        setTimeout(function () {
          setRetorno("");
        }, 8000);
      })
      .catch((error) => {
        console.log(error);
        setSev("danger");
        setLoading(false);

        setTimeout(function () {
          setRetorno("");
        }, 5000);

        setRetorno(error.message);
      });
    }else{
      setSev("warning");
      setLoading(false);
      setRetorno("Por favor, preencha os campos obrigatórios!");
    }
  }

  return (
    <>
      <ModalContext.Provider value={{ CloseVejaMais }}>
        <MNFE abre={VMais} />
      </ModalContext.Provider>

      <ModalContext.Provider value={{ CloseVejaMais }}>
        <MMDFE abre={VMais} />
      </ModalContext.Provider>

      <ModalContext.Provider value={{ CloseVejaMais }}>
        <MConheca abre={VMais} />
      </ModalContext.Provider>

      <DemoNavbar />
      <main>
        <div className="position-relative">
        <a
          href="https://wa.me/5566999984060?text=Olá Brasil sistemas, gostaria de saber mais sobre o emitir DFE."
          class="whatsapp_float"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i class="fa fa-whatsapp whatsapp-icon"></i>
        </a>
          {/* shape Hero */}
          <section className="section section-lg section-shaped pb-250">
            <div className="shape shape-style-1 shape-default">
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
              <span />
            </div>
            <Container className="py-lg-md d-flex">
              <div className="col px-0">
                <Row>
                  <Col lg="6">
                    <h1 className="display-3 text-white">
                      <span>Faça suas emissões fiscais de forma rápida e fácil. </span>
                    </h1>
                    <p className="lead text-white">
                      Tenha o controle de seus documentos fiscais na palma de sua mão.
                    </p>
                    <Row>
                      <Col lg="6">
                        <img
                          alt="..."
                          id="imgNFE"
                          src={require("../../assets/img/icons/Docs/NFe.png")}
                        />
                        <UncontrolledTooltip delay={0} target="imgNFE">
                          Nota fiscal Eletrônica
                        </UncontrolledTooltip>
                      </Col>
                      <Col lg="6">
                        <img
                          alt="..."
                          id="imgMDFe"
                          src={require("../../assets/img/icons/Docs/MDFe.png")}
                        />
                        <UncontrolledTooltip delay={0} target="imgMDFe">
                          Manifesto Eletrônico de Documentos Fiscais
                        </UncontrolledTooltip>
                      </Col>
                    </Row>
                  </Col>
                  <Col lg="6">
                    <img
                      alt="..."
                      src={require("assets/img/brand/IMod.png")}
                    />
                  </Col>
                </Row>
              </div>
            </Container>
            {/* SVG separator */}
            <div className="separator separator-bottom separator-skew">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-white"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </section>
          {/* 1st Hero Variation */}
        </div>
        <section className="section section-lg pt-lg-0 mt--200"> 
          <Container>
            <Row className="justify-content-center">
              <Col lg="12">
                <Row className="row-grid">
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <Col lg="12" style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                          <div className="icon icon-shape icon-shape-success rounded-circle mb-4">
                            <i className="ni ni-money-coins" />
                          </div>
                          <h6 className="text-success text-uppercase" style={{ marginLeft: 5, padding: 0, marginBottom: 15 }}>
                            Controle fiscal - NFe
                          </h6>
                        </Col>
                        <p className="description mt-3 text-center">
                          Com a nossa plataforma você pode fazer: 
                        </p>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}> 
                          <Badge color="success" pill className="mr-1" >
                            Emissão
                          </Badge>
                          <Badge color="success" pill className="mr-1" style={{ marginBottom: 2, marginTop: 2 }}>
                            Cancelamento
                          </Badge>
                          <Badge color="success" pill className="mr-1" >
                            Cartas de correção
                          </Badge>
                        </div>
                        <p className="description mt-3 text-center">
                          E ainda toda parte relatorial.
                        </p>
                        <Button
                          className="mt-4"
                          color="success"
                          onClick={() => VejaMais(0)}
                          block
                        >
                          Veja mais
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <Col lg="12" style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                          <div className="icon icon-shape icon-shape-info rounded-circle mb-4">
                            <i className="fa fa-truck" />
                          </div>
                          <h6 className="text-info text-uppercase" style={{ marginLeft: 5, padding: 0, marginBottom: 15, fontSize: 15 }}>
                            Controle fiscal - MDFe
                          </h6>
                        </Col>
                        <p className="description mt-3">
                          Faça as seguintes ações com facilidade e rapidez: 
                        </p>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}> 
                          <Badge color="info" pill className="mr-1">
                            Emissão, averbação e impressão
                          </Badge>
                          <Badge color="info" pill className="mr-1" style={{ marginBottom: 2, marginTop: 2, fontSize: 9 }}>
                            Encerramento e desconhecimento transp.
                          </Badge>
                          <Badge color="info" pill className="mr-1">
                            Cancelamento
                          </Badge>
                        </div>
                        <p className="description mt-3 text-center">
                          Tire relatórios e tenha o controle total de suas cargas.
                        </p>
                        <Button
                          className="mt-4"
                          color="info" 
                          block
                          onClick={() => VejaMais(1)}
                        >
                          Veja mais
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col lg="4">
                    <Card className="card-lift--hover shadow border-0">
                      <CardBody className="py-5">
                        <Col lg="12" style={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
                          <div className="icon icon-shape icon-shape-primary rounded-circle mb-4">
                            <i className="fa fa-money" />
                          </div>
                          <h6 className="text-primary text-uppercase" style={{ marginLeft: 5, padding: 0, marginBottom: 15, fontSize: 15  }}>
                            Planos que cabem no seu bolso!
                          </h6>
                        </Col>
                        <p className="description mt-3">
                          Tenha controle de multiplos emissores, emissão por lote e muito mais.
                        </p>
                        <div style={{ display: "flex", justifyContent: "center", flexDirection: "column" }}> 
                          <Badge color="primary" pill className="mr-1">
                            Controle de usuários temporários
                          </Badge>
                          <Badge color="primary" pill className="mr-1" style={{ marginBottom: 2, marginTop: 2 }}>
                            Sua logo nos documentos fiscais
                          </Badge>
                          <Badge color="primary" pill className="mr-1">
                            Planos que atendem sua necessidade
                          </Badge>
                        </div>
                        <Button
                          className="mt-4"
                          color="primary"
                          onClick={() => VejaMais(2)}
                          block
                        >
                          Conheça nossos planos
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg bg-gradient-dark textWhite">
          <Container>
            <Row className="row-grid align-items-center textWhite">

              <Col md="12">
                <div className="pr-md-5 textWhite" style={{ color: "white" }}>
                  <h3 style={{ color: "white" }}>Mas o que é o Emitir DFe?</h3>
                  <p>
                    Emita suas notas fiscais e seus manifesto de transporte on-line, sem precisar instalar nada!
                  </p>
                </div>
              </Col>

              <Col sm="12" md="6" lg="6">
                <Card body>
                  <div className="d-flex align-items-center">
                    <div className="icon" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexShrink: 0, width: 55, height: 55, marginLeft: -65,backgroundColor: "#00C668", borderRadius: 8, marginTop: -100 }}>
                      <FontAwesomeIcon style={{color: "white" }}  icon={faCheck} />
                    </div>

                    <div style={{ padding: 10 }}>
                      <h6 className="mb-0">
                        O Emitir DFe é uma plataforma de emissões fiscais que você pode usar sem precisar instalar nada!
                      </h6>
                      <p style={{ textAlign: "right" }}><small>Usando qualquer navegador e a internet, emita de qualquer lugar... Inclusive de seu celular!</small></p>
                    </div>
                  </div>
                </Card>
              </Col>

              <Col sm="12" md="6" lg="6">
                <Card body>
                  <div className="d-flex align-items-center">
                    <div className="icon" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexShrink: 0, width: 55, height: 55, marginLeft: -65,backgroundColor: "#00C668", borderRadius: 8, marginTop: -100 }}>
                      <FontAwesomeIcon style={{color: "white" }}  icon={faCheck} />
                    </div>

                    <div style={{ padding: 10 }}>
                      <h6 className="mb-0">Você precisa emitir apenas nota fiscal? Ou apenas seu manifesto de transporte?</h6>
                      <p style={{ textAlign: "right" }}><small>Com nossa plataforma você usa apenas o que precisa sem precisar pagar a mais por isso.</small></p>
                    </div>
                  </div>
                </Card>
              </Col>
            </Row>

            <Row className="row-grid align-items-center textWhite">
              <Col sm="12" md="6" lg="6">
                <Card body>
                  <div className="d-flex align-items-center">
                    <div className="icon" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexShrink: 0, width: 55, height: 55, marginLeft: -65,backgroundColor: "#00C668", borderRadius: 8, marginTop: -100 }}>
                      <FontAwesomeIcon style={{color: "white" }}  icon={faCheck} />
                    </div>

                    <div style={{ padding: 10 }}>
                      <h6 className="mb-0">Cadastre sua empresa, configure sua entidade emissora, vincule seu certificado digital e pronto. É só começar a emitir.</h6>
                      <p style={{ textAlign: "right" }}><small>Sem precisar fazer horas e horas de treinamento, isso mesmo. Com poucos cliques você estará utilizando nossa plataforma.</small></p>
                    </div>
                  </div>
                </Card>
              </Col>

              <Col sm="12" md="6" lg="6">
                <Card body>
                  <div className="d-flex align-items-center">
                    <div className="icon" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexShrink: 0, width: 55, height: 55, marginLeft: -65,backgroundColor: "#00C668", borderRadius: 8, marginTop: -100 }}>
                      <FontAwesomeIcon style={{color: "white" }}  icon={faCheck} />
                    </div>

                    <div style={{ padding: 10 }}>
                      <h6 className="mb-0">
                        Com planos acessíveis que se encaixam na realidade da sua empresa e principalmente, no seu bolso.
                      </h6>
                      <p style={{ textAlign: "right" }}><small><a href="javasctipt:void(0)" onClick={() => VejaMais(2)}>Confirma nossos planos e escolha o melhor para sua empresa</a></small></p>
                    </div>
                  </div>
                </Card>
              </Col>

            </Row>
          </Container>
        </section>
        <section className="section section-lg bg-gradient-lighter">
          <Container>
            <Row className="row-grid align-items-center">
              <Col className="order-md-2" md="6">
                <img
                  alt="..."
                  className="img-fluid floating"
                  src={require("assets/img/theme/promo-1.png")}
                />
              </Col>
              <Col className="order-md-1" md="6">
                <div className="pr-md-5">
                  <h3>Algumas funcionalidades</h3>
                  <p>
                    Facilidade e praticidade são nossas principais armas 
                  </p>
                  <ul className="list-unstyled mt-5">
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          >
                            <i className="ni ni-single-copy-04" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Crie modelos de notas e realize suas emissões com poucos cliques. 
                          </h6>
                        </div>
                      </div>
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3" 
                            color="success"
                          >
                            <i className="ni ni-single-02" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">Crie usuários temporários.</h6>
                        </div>
                      </div> 
                    </li>
                    <li className="py-2">
                      <div className="d-flex align-items-center">
                        <div>
                          <Badge
                            className="badge-circle mr-3"
                            color="success"
                          > 
                            <i className="ni ni-settings" />
                          </Badge>
                        </div>
                        <div>
                          <h6 className="mb-0">
                            Configure seu emissor de forma rápida e simples.
                          </h6>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="section section-lg pt-0">
          <Container>
            <div className="p-5">
              <Row className="align-items-center">
                <Col lg="8">
                  <h3 className="">
                    Gostaria de saber mais sobre nossas soluções?
                  </h3>
                  <p className="lead  mt-3">
                    A Brasil Sistemas e Tecnologia é uma empresa que atua dentro do ecossistema agro e possue várias soluções e ferramentas para facilitar o dia-a-da dos nossos usuários.
                  </p>
                  <p className="lead  mt-3">
                    Transportadoras, fazendas, armazéns... Temos tudo para vocês! <b>Clique e veja mais</b>
                  </p>
                </Col> 
                <Col className="ml-lg-auto" lg="3">
                  <Button
                    block
                    className="btn-white"
                    color="default"
                    href="https://brst.com.br/solucoes.html"
                    size="lg"
                    target="_blank"
                  >
                    <img src={require("assets/img/icons/favicon.png")} alt="..."></img> Brasil Sistemas e Tecnologia
                  </Button>
                </Col>

                <Col className="ml-lg-auto" lg="12"><Download /></Col>
              </Row>
            </div>
          </Container>
        </section>

        <section className="section section-lg bg-gradient-default">
          <Container className="pt-lg pb-300">
            <Row className="text-center justify-content-center">
              <Col lg="10">
                <h2 className="display-3 text-white">Gostaria de saber mais sobre o Emitir DFE?</h2>
                <p className="lead text-white">
                  Nos envie sua sugestão, mensagem, contato e em breve retornaremos. 
                </p>
              </Col>
            </Row>
          </Container>
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew zindex-100" id="Contato">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white" 
                points="2560 0 2560 100 0 100" 
              />
            </svg>
          </div>
        </section>
        
        <section className="section section-lg pt-lg-0 section-contact-us">
          <Container>
            <Row className="justify-content-center mt--300"> 
              <Col lg="8">
                <Card className="bg-gradient-secondary shadow">
                  <CardBody className="p-lg-5">
                    <h4 className="mb-1">Fale com a gente</h4>
                    <p className="mt-0">
                      Estamos aqui para ajudar
                    </p>
                    <FormGroup
                      className={classnames("mt-5", {
                        focused: nameFocused
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="Seu nome"
                          type="text"
                          value={cNome}
                          onChange={(e) => setcNome(e.target.value)}
                          onFocus={e => setnameFocused(true)}
                          onBlur={e => setnameFocused(false)}
                        />
                      </InputGroup>

                      { aNome ? ( <Alert style={{ marginTop: 5 }} color={"warning"}>* Preencha este campo. </Alert>  ) : null }

                    </FormGroup>
                    <FormGroup
                      className={classnames({
                        focused: emailFocused
                      })}
                    >
                      <InputGroup className="input-group-alternative">
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="ni ni-email-83" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder="E-mail"
                          type="email"
                          value={cMail}
                          onChange={(e) => setcMail(e.target.value)}
                          onFocus={e => setemailFocused(true)}
                          onBlur={e => setemailFocused(false)}  
                        />
                      </InputGroup>

                      { aMail ? ( <Alert style={{ marginTop: 5 }} color={"warning"}>* Preencha este campo. </Alert>  ) : null }
                    </FormGroup>
                    <FormGroup className="mb-4">
                      <Input
                        className="form-control-alternative" 
                        cols="80"
                        name="name"
                        placeholder="Escreva sua mensagem" 
                        rows="4"
                        type="textarea"
                        value={cMSG}
                        onChange={(e) => setcMSG(e.target.value)}
                      />

                      { aMSG ? ( <Alert style={{ marginTop: 5 }} color={"warning"}>* Preencha este campo. </Alert>  ) : null }
                    </FormGroup>
                    <div>
                      <Button
                        block
                        className="btn-round" 
                        color="default"
                        size="lg"
                        type="button"
                        onClick={() => EnviaContato()}
                      >
                        Enviar mensagem
                      </Button>

                      { Loading ? (
                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: 10 }}>
                          <Spinner color="dark" type="grow" > Loading... </Spinner>
                          <Spinner color="dark" type="grow" > Loading... </Spinner>
                          <Spinner color="dark" type="grow" > Loading... </Spinner>
                        </div>
                      ) : null }
                    </div>

                    { Retorno !== "" ? (
                      <Alert color={Sev}  style={{ marginTop: 5 }} >
                        {Retorno}
                      </Alert>
                    ) : null }
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
      </main>
      <CardsFooter />
    </>
  );
}
