/*!

=========================================================
* Argon Design System React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-design-system-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-design-system-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState, useContext, useEffect } from "react";
// reactstrap components
import {
  Button,
  Row,
  Col,
  Modal, ModalBody, ModalHeader, ModalFooter,
  UncontrolledCarousel
} from "reactstrap";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

//DEV - BRST
import ModalContext from 'components/Context/ModalContext';

//class Landing extends React.Component {
export default function MMDFE( props ) { 
    const [VMais, setVMais] = useState(false);
    const { CloseVejaMais } = useContext(ModalContext);

    const items = [
        {
        src: require("assets/img/theme/img-1-1200x1000.jpg"),
        altText: "",
        caption: "",
        header: ""
        },
        {
        src: require("assets/img/theme/img-2-1200x1000.jpg"),
        altText: "",
        caption: "",
        header: ""
        }
    ];

    function Close(){
      setVMais(false);
        CloseVejaMais()
    }

    function SejaCliente(){
        Close();
    }

    useEffect(() => {
        if ( props.abre === 1 ) {
            setVMais(true);
        }
    }, [props.abre]);

  return (
    <>
        <Modal onClose={Close} size={"lg"} isOpen={VMais}>
          <ModalHeader cssModule={{'modal-title': 'w-100 text-center'}}>
            Apresentando o módulo de notas fiscais eletrônicas: MDFE
          </ModalHeader>

          <ModalBody>
            <Row>
              <Col className="mb-lg-auto" lg="6" md="6" sm="12">
                <div className="rounded shadow-lg overflow-hidden transform-perspective-right">
                  <UncontrolledCarousel items={items} />
                </div>
              </Col>
              <Col className="mb-lg-auto" lg="6" md="6" sm="12" style={{ textAlign: "center" }}>
                <p>
                  <FontAwesomeIcon style={{color: "green" }} icon={faCheck} />
                  { " " }
                  Emita seus MDFe's com poucos cliques. Mantemos um cadastro de suas placas, produtos e motoristas, assim, otimizando suas emissões.
                </p>

                <p>
                  <FontAwesomeIcon style={{color: "green" }} icon={faCheck} />
                  { " " }
                  Você faz a emissão de suas notas fiscais por nossa plataforma também? Importe os dados diretamente no MDFe dando mais agilidade a suas emissões.
                </p>

                <p>
                  <FontAwesomeIcon style={{color: "green" }} icon={faCheck} />
                  { " " }
                  Faça seu encerramento de forma fácil e rápida. Aqui você também consegue fazer cancelamento de sua MDFe's.
                </p>

                <p>
                  <FontAwesomeIcon style={{color: "green" }} icon={faCheck} />
                  { " " }
                  E ainda temos o serviço de averbação diretamente em nossa plataforma.
                </p>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="info" onClick={() => SejaCliente()}>
              Seja nosso cliente
            </Button>
            {' '}
            <Button onClick={() => Close()}>
              Fechar
            </Button>
          </ModalFooter>
        </Modal>
    </>
  );
}
